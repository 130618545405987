<template>
  <base-section space="0">
    <div v-for="(item, id) in m_arrContent" :key="id">
      <k-d-a-consult-point-card
        :str-consult="item.strTitle"
        :str-description="item.htmlDescription"
        :img-consult="item.imgSrc"
        :num-index="id"
      />
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAConsultPoint',

  components: {
    KDAConsultPointCard: () => import('@/components/KDAConsultPointCard')
  },

  mixins: [BaseBreakpoint],

  computed: {
    m_arrContent() {
      const pointConsult = [];

      for (let i = 1; i <= 3; i++) {
        pointConsult.push({
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.consult.point${i}.title`),
          htmlDescription: this.$vuetify.lang.t(`$vuetify.kda.consult.point${i}.desc`),
          imgSrc: require(`@/assets/photos/consults-0${i}.png`)
        });
      }

      return pointConsult;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background {
  background-color: #fbfbfb;
}
</style>
